import styled from "styled-components";
import IconWrapper from "./IconWrapper";
import Text from "./copys/Text";
import { useMemo } from "react";

import Medal from "./icons/Medal";
import Gem from "./icons/Gem";
import Coin from "./icons/Coin";

export default ({ type, count = 0, shadow, ...rest }) => {
  const countWithSpaces = useMemo(() => (count + "").replace(/\B(?=(\d{3})+(?!\d))/g, " "), [count]);

  return (
    <Wrapper $shadow={shadow} {...rest}>
      <Count>{countWithSpaces}</Count>
      <StyledIconWrapper>
        {type === "medal" && <Medal />}
        {type === "gem" && <Gem />}
        {type === "coin" && <Coin />}
      </StyledIconWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  ${(p) => p.$shadow && `filter: drop-shadow(${p.theme.shadow});`}
`;

const Count = styled(Text)`
  font-weight: 700;
`;

const StyledIconWrapper = styled(IconWrapper)`
  --stroke-color: ${(p) => p.theme.white[700]};
`;
