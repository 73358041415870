import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <circle
      cx={128}
      cy={128}
      r={108}
      stroke="var(--stroke-color)"
      strokeWidth="var(--stroke-width)"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
    />
    <circle cx={128} cy={128} r={108} fill="#FFAA47" />
    <path
      fill="#FFE791"
      d="M120.826 57.536c2.935-5.946 11.413-5.946 14.348 0l15.946 32.31a7.998 7.998 0 0 0 6.023 4.376l35.656 5.18c6.561.954 9.181 9.018 4.433 13.646l-25.8 25.149a8 8 0 0 0-2.301 7.082l6.091 35.511c1.12 6.535-5.739 11.519-11.608 8.433l-31.891-16.766a8.003 8.003 0 0 0-7.446 0l-31.891 16.766c-5.869 3.086-12.728-1.898-11.608-8.433l6.091-35.511a8.001 8.001 0 0 0-2.3-7.082l-25.801-25.149c-4.748-4.628-2.128-12.692 4.434-13.645l35.655-5.181a7.998 7.998 0 0 0 6.023-4.377l15.946-32.31Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
