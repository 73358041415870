import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <path
      stroke="var(--stroke-color)"
      strokeWidth="var(--stroke-width)"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
      d="m101.191 202.702-75.224-83.934c-10.653-11.887-12.207-29.37-3.816-42.95l22.084-35.741A36 36 0 0 1 74.861 23h105.893a36 36 0 0 1 30.535 16.932l22.381 35.84c8.488 13.593 6.969 31.161-3.726 43.095l-75.135 83.835c-14.308 15.965-39.31 15.965-53.618 0Z"
    />
    <path
      fill="#00D45A"
      d="m101.191 202.702-75.224-83.934c-10.653-11.887-12.207-29.37-3.816-42.95l22.084-35.741A36 36 0 0 1 74.861 23h105.893a36 36 0 0 1 30.535 16.932l22.381 35.84c8.488 13.593 6.969 31.161-3.726 43.095l-75.135 83.835c-14.308 15.965-39.31 15.965-53.618 0Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.5}
      d="m221.168 88.016-24.641-40.238A9.999 9.999 0 0 0 187.999 43h-16.962c-7.853 0-12.642 8.637-8.481 15.298l32.012 51.249c3.559 5.698 11.611 6.336 16.022 1.27l9.591-11.011a10 10 0 0 0 .987-11.79Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
